import {
	createRouter,
	createWebHashHistory
} from 'vue-router'

const routes = [{
		path: '/',
		name: 'home',
		component: () => import('../views/HomeView.vue'),
		children: [{
				path: '/',
				name: 'index',
				component: () => import('../views/index/IndexView.vue')
			},
			{
				path: '/product',
				name: 'product',
				component: () => import('../views/product/ProductView.vue')
			},
			{
				path: '/about',
				name: 'about',
				component: () => import('../views/about/AboutView.vue')
			}
		]
	},
	{
		path: '/user-agreement',
		name: 'UserAgreement',
		component: () => import('../views/agreement/UserAgreement.vue')
	}
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

export default router
